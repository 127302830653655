import React from 'react';

import { ArrowUpWideNarrow, BarChart2, Megaphone, MessagesSquare, Search } from 'lucide-react';

import GenericLandingHelmet, { type Props as Helmet } from 'common/helmets/GenericLandingHelmet';
import nbspLastSpace from 'common/util/nbspLastSpace';
import LandingImages from 'common/www/assets/LandingImages';
import TestimonialData from 'common/www/LandingV3/Data/TestimonialData';
import LandingContentBlock, { type Props as Block } from 'common/www/LandingV3/LandingContentBlock';
import LandingCTA, { type Props as CTA } from 'common/www/LandingV3/LandingCTA';
import LandingFAQ, { type LandingFAQProps } from 'common/www/LandingV3/LandingFAQ';
import LandingHero, { type Props as Hero } from 'common/www/LandingV3/LandingHero';
import LandingTabsAsLinks, {
  type Props as Tabs,
} from 'common/www/LandingV3/LandingTabs/LandingTabsAsLinks';
import LandingTestimonials, {
  type Props as Testimonials,
} from 'common/www/LandingV3/LandingTestimonials';

import 'css/components/www/LandingV3/_LandingBugTracking.scss';

const HelmetData: Helmet = {
  title: 'Feedback-driven bug and issue tracking tool',
  description:
    'Easily capture, prioritize and fix bug reports while keeping your users in the loop. Get started for free today.',
  pageURL: 'https://canny.io/features/bug-tracking-tool',
};

const HeroSection: Hero = {
  copy: {
    pretitle: 'Issue tracking',
    title: nbspLastSpace('Simplify your bug tracking system'),
    subtitle:
      'Track and prioritize bug reports wherever users find them. Keep everyone updated as you fix them.',
    registerButton: 'Get started for free',
    cost: 'No credit card required',
  },
};

const ProductCycle: Tabs = {
  copy: {
    title: 'Your complete bug tracking system',
    subtitle: 'Listen to your customers and action their feedback',
  },
  tabs: [
    {
      title: 'Feedback discovery',
      body: 'Let users report bugs and track them in your feedback board',
      icon: MessagesSquare,
      url: '/features/autopilot',
    },
    {
      title: 'Analyze feedback',
      body: "Understand each bug's severity and impact on different users",
      icon: Search,
      url: '/features/analyze-feedback',
    },
    {
      title: 'Prioritize requests',
      body: "Prioritize and plan which bugs you'll fix and share status updates",
      icon: ArrowUpWideNarrow,
      url: '/features/product-roadmap',
    },
    {
      title: 'Build roadmaps',
      body: "Create roadmaps to show customers you're working on their feedback",
      icon: BarChart2,
      iconClassModifier: 'rotate-90',
      url: '/use-cases/public-roadmap',
    },
    {
      title: 'Share updates',
      body: 'Announce bug resolution and automatically share details with reporters',
      icon: Megaphone,
      url: '/features/product-changelog',
    },
  ],
};

const EDFContentBlocks: Block[] = [
  {
    copy: {
      title: 'Easily capture bugs and feedback',
      subtitle:
        'Let users share bug reports in your portal or collect them via our support tool integrations. Instantly prioritize issues as they come in.',
    },
    image: {
      src: LandingImages.capture_bugs,
      alt: 'Allow users to submit bugs through a customizable form, or through Intercom, Zendesk, Help Scout, or Salesforce.',
    },
  },
  {
    copy: {
      title: 'Keep users updated on bug reports',
      subtitle:
        'Create a public bug tracking board and roadmap. Let users see, vote on, and track bugs. Automatically send email updates as you work on fixes.',
    },
    image: {
      src: LandingImages.bugs_notify,
      alt: 'An email following up to a specific user for a feedpack post marked as complete.',
    },
  },
  {
    copy: {
      title: 'Sync with your project management tool',
      subtitle:
        'Connect to project management tools like Jira and ClickUp. Sync bug reports, assign tasks, and automatically update posts.',
    },
    image: {
      src: LandingImages.bugs_roadmap,
      alt: 'A private roadmap that tracks and differentiates types of bugs, alongside prioritization options.',
    },
  },
  {
    copy: {
      title: 'Support internal bug tracking and testing',
      subtitle:
        'Use our bug tracking tools privately with your team. Control which users can access your board and roadmap. Add internal comments to discuss issues.',
    },
    image: {
      src: LandingImages.internal_bugs,
      alt: 'A private post with an internal conversation about getting a fix reviewed and out to customers.',
    },
  },
];

const BugTrackingTestimonials: Testimonials = {
  copy: {
    title: 'Leading companies count on Canny for bug and issue tracking',
    subtitle:
      "Don't take our word for it. See how companies like yours are tracking bugs and keeping users happy.",
  },
  categorizedTestimonials: {
    general: [
      TestimonialData.Pallyy(),
      TestimonialData.Strapi(
        "It just works. The UX/UI is simple, people have no questions about using it. It's the kind of product that I really love."
      ),
      TestimonialData.AgencyAnalytics(
        "Canny has played a huge role in our understanding of what our customers need. It's now easy to see what they need now, and what they need in the long term."
      ),
    ],
  },
};

const FAQ: LandingFAQProps = {
  faqs: [
    {
      question: 'Can Canny help with more than bug tracking? ',
      answer: [
        'We offer a range of product management tools covering feedback management, prioritization, roadmapping, and release notes. Canny is a tool that helps you close the feedback loop. This process supports bug reporting and resolution well.',
      ],
    },
    {
      question: 'How much does it cost?',
      answer: [
        "You can set up a free plan to start. It's fully functional free bug tracking software. Some of our more powerful features are on our paid plans which start at $79 a month.", // update if starter ever changes price
      ],
    },
    {
      question: 'Will everyone see all our bugs?',
      answer: [
        "It's up to you. You can make your feedback board and roadmap public. Or, you can restrict it to specific users based on filters you set in your account. You can also restrict boards to just your internal teams. This can be really helpful when your software development team is working on early versions of features.",
      ],
    },
    {
      question: 'What tools do you integrate with?',
      answer: [
        'We connect to a wide range of tools to support your workflow.',
        'That includes:',
        [
          'Project management tools like Jira, GitHub, ClickUp, Linear, Azure DevOps, and Asana',
          'Customer communication tools like Intercom, Zendesk, Salesforce, HubSpot, and Microsoft Teams',
          'Authentication tools like Google Workspace, Okta, OpenID, and OneLogin',
          'Automation tools like Zapier and Make',
        ],
        {
          node: (
            <p>
              Check out our{' '}
              <a href="https://canny.io/integrations" rel="noopener" target="_blank">
                full list of integrations.
              </a>
            </p>
          ),
          text: 'Check out our <a href="https://canny.io/integrations" rel="noopener" target="_blank">full list of integrations.</a>',
        },
      ],
    },
  ],
};

const cta: CTA = {
  copy: {
    title: 'Set up your bug tracking system',
    subtitle: 'Get started in minutes or schedule a quick demo today.',
    demoButton: 'Request a demo',
    registerButton: 'Get started',
  },
};

const LandingBugTracking = () => {
  return (
    <>
      <GenericLandingHelmet {...HelmetData} />
      <main className="LandingBugTracking" id="main">
        <LandingHero {...HeroSection} />
        <LandingTabsAsLinks {...ProductCycle} />
        {EDFContentBlocks.map((block, index) => {
          return (
            <LandingContentBlock
              flip={index % 2 === 1}
              {...block}
              key={`Autopilot__content-block__${index}`}
            />
          );
        })}
        <LandingTestimonials centerHeading {...BugTrackingTestimonials} />
        <LandingFAQ {...FAQ} />
        <LandingCTA
          style={{
            backgroundImage: `url(${LandingImages.gradient1})`,
          }}
          orientation="vertical"
          className="LandingBugTracking__cta"
          {...cta}
        />
      </main>
    </>
  );
};

export default LandingBugTracking;
