import React from 'react';

import { ArrowRight, CreditCard } from 'lucide-react';

import 'css/components/www/LandingV3/_LandingHero.scss';

import Link from 'common/Link';
import { demoLink, registerLink } from 'common/routeComponents/landing/core/constants';
import { P } from 'common/ui/Text';
import nbspLastSpace from 'common/util/nbspLastSpace';

type Copy = {
  pretitle?: string;
  title: string;
  subtitle: string;
  demoButton?: string;
  registerButton?: string;
  cost?: string;
};

export type Props = {
  copy: Copy;
  registerLinkOverride?: string;
  demoLinkOverride?: string;
};

const LandingHero = ({
  copy: { pretitle, title, subtitle, demoButton, registerButton, cost },
  registerLinkOverride = registerLink,
  demoLinkOverride = demoLink,
}: Props) => {
  return (
    <section className="LandingHero">
      {pretitle && <p className="LandingHero__pretitle">{pretitle}</p>}
      <h1 className="LandingHero__title">{title}</h1>
      <p className="LandingHero__subtitle">{nbspLastSpace(subtitle)}</p>
      {(registerButton || demoButton) && (
        <div className="LandingHero__ctas">
          {demoButton && (
            <Link
              to={demoLinkOverride}
              className="LandingHero__button LandingHero__button--outlined">
              {demoButton}
            </Link>
          )}
          {registerButton && (
            <Link to={registerLinkOverride} className="LandingHero__button LandingHero__button">
              {registerButton}
              <ArrowRight aria-hidden size={20} strokeWidth={1.5} />
            </Link>
          )}
        </div>
      )}
      {cost && (
        <P variant="bodyLg" className="LandingHero__cost">
          <CreditCard aria-hidden size={18} strokeWidth={1.5} />
          {cost}
        </P>
      )}
    </section>
  );
};

export default LandingHero;
